import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from 'styled-components'
import siema from "../../javascript/siema.min.js";

import quotation from '../../../static/img/quotation.png'

// extend a Siema class by two methods
// addDots - to create a markup for dots
// updateDots - to update classes on dots on change callback
class SiemaWithDots extends siema {
  addDots() {
    // create a contnier for all dots
    // add a class 'dots' for styling reason
    this.dots = document.createElement("div");
    this.dots.classList.add("dots");

    // loop through slides to create a number of dots
    for (let i = 0; i < this.innerElements.length; i++) {
      // create a dot
      const dot = document.createElement("button");

      // add a class to dot
      dot.classList.add("dots__item");

      // add an event handler to each of them
      dot.addEventListener("click", () => {
        this.goTo(i);
      });

      // append dot to a container for all of them
      this.dots.appendChild(dot);
    }

    // add the container full of dots after selector
    this.selector.parentNode.insertBefore(this.dots, this.selector.nextSibling);
  }

  updateDots() {
    // loop through all dots
    for (let i = 0; i < this.dots.querySelectorAll("button").length; i++) {
      // if current dot matches currentSlide prop, add a class to it, remove otherwise
      const addOrRemove = this.currentSlide === i ? "add" : "remove";
      this.dots
        .querySelectorAll("button")
      [i].classList[addOrRemove]("dots__item--active");
    }
  }
}

class CarouselTestimonials extends Component {
  render() {
    const { testimonials } = this.props;

    return (
      <div className="siema">
        {testimonials.map((testimonial, id) => {
          const { image, quote, author } = testimonial;

          return (
            <article key={id} className="message">
              <MessageWrapper>
                {image && (
                  <ImageWrapper>
                    <img src={image} alt="review picture" />
                  </ImageWrapper>
                )}
                <div className="message-body">
                  {quote}
                  <br />
                  <cite>{author}</cite>
                </div>
              </MessageWrapper>
            </article>
          )
        })}
      </div>
    );
  }

  componentDidMount() {
    // instantiate new extended Siema
    const mySiema = new SiemaWithDots({
      // on init trigger method created above
      onInit: function () {
        this.addDots();
        this.updateDots();
      },

      // on change trigger method created above
      onChange: function () {
        this.updateDots();
      }
    });
  }
}

const Testimonials = ({ testimonials }) => (
  <CarouselTestimonials testimonials={testimonials} />
);

// componentDidMount(){new siema();console.log('done!');}

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string
    })
  )
};

//new siema();

export default Testimonials;

const MessageWrapper = styled.div`
  @media(min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message-body {
    max-width: 650px;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  min-width: 200px;
  max-width: 200px;
  margin: 0 auto;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 48px;
    background: url(${quotation}) no-repeat;
  }

  @media(min-width: 768px) {
    margin: 0;
  }
`
