import React from 'react'
import Helmet from 'react-helmet'
import Offerings from '../Offerings'
import LowerOfferings from '../LowerOfferings'
import Testimonials from '../Testimonials'
import PropTypes from 'prop-types'

const HomePageTemplate = ({
  meta,
  hero,
  offerings,
  website,
  lowerofferings,
  testimonials,
  banner1,
  banner2,
  banner3,
}) => (
  <div>
    <Helmet>
      <title>{meta.meta_title}</title>
      <meta name='description' content={meta.meta_description} />
    </Helmet>

    <section className='hero is-primary is-bold'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns'>
            <div className='column hero-description'>
              <div className='section'>
                <h1>{hero.heading}</h1>
                <h2 className='has-text-weight-semibold is-size-2'>
                  {hero.description}
                </h2>
                <br />
                {(!!hero.ft_button.url && !!hero.ft_button.text) && (
                  <div>
                    <a
                      className='button free-trial-btn2'
                      href={hero.ft_button.url}
                    >
                      {hero.ft_button.text}
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className='column'>
              <img
                alt='TalkGuest - Property Management System'
                className='hero-computer'
                src='/img/macbook.png'
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='section section--gradient'>
      <div className='container'>
        <div className=''>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='columns partners-logos'>
                <div className='column partner-logo'>
                  <img alt='Airbnb channel manager' src='/img/airbnb.png' />
                </div>
                <div className='column partner-logo'>
                  <img
                    alt='Booking.com channel manager'
                    src='/img/booking-com.png'
                  />
                </div>
                <div className='column partner-logo'>
                  <img alt='Expedia channel manager' src='/img/expedia.png' />
                </div>
                <div className='column partner-logo'>
                  <img alt='Homeaway channel manager' src='/img/homeaway.png' />
                </div>
                <div className='column partner-logo'>
                  <img
                    alt='Tripadvisor channel manager'
                    src='/img/tripadvisor.png'
                  />
                </div>
              </div>

              <div className='content'>
                <Offerings gridItems={offerings.blurbs} />
                <div className='hp-banner1'>
                  <h2>{banner1.heading1}</h2>
                  <h3>{banner1.heading2}</h3>
                </div>

                <LowerOfferings gridItems={lowerofferings.blurbs} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div className='hp-banner2'>
      <div className='container'>
        <div className='columns'>
          <div className='column'>
            <h2>{banner2.heading1}</h2>
            <h3>{banner2.heading2}</h3>
          </div>
          {(banner2.ft_button.url && banner2.ft_button.text) && (
            <div className='column trialelement'>
              <a
                className='button free-trial-btn2'
                href={banner2.ft_button.url}
              >
                {banner2.ft_button.text}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>

    <div className='content' />

    <section className='section section--gradient'>
      <div className='container'>
        <Testimonials testimonials={testimonials} />
      </div>
    </section>

    <div className='hp-banner3'>
      <div className='container'>
        <h2>{banner3.heading1}</h2>
        <div className='offer-checklist columns is-multiline'>
          {printChecklist(banner3.checklist)}
        </div>
        {(banner3.ft_button.url && banner3.ft_button.text) && (
          <a
            className='button free-trial-btn2'
            href={banner3.ft_button.url}
          >
            {banner3.ft_button.text}
          </a>
        )}
      </div>
    </div>
  </div>
)

function printChecklist (checklistToPrint) {
  checklistToPrint = checklistToPrint || []

  const listItems = checklistToPrint.map(function (item, i) {
    return (
      <div className='column' key={i}>
        <img alt='Vantagens do Channel Manager' src='/img/done.png' />
        {item}
      </div>
    )
  })

  return listItems
}

HomePageTemplate.propTypes = {
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  hero: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
  }),
  meta: PropTypes.shape({
    meta_title: PropTypes.string,
    meta_description: PropTypes.string,
  }),
  website: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  lowerofferings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  testimonials: PropTypes.array,
  checklist: PropTypes.array,
}

export default HomePageTemplate
