import React from "react";
import PropTypes from "prop-types";

const LowerOffering = props => (
  <div className="LowerOffering">
    <img className="heading-img" alt="" src={props.image} />
    <p className="heading">{props.heading}</p>
    <p className="text">{props.text}</p>
  </div>
);

const LowerOfferings = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map(item => (
      <div key={item.image} className="column is-4">
        <LowerOffering
          heading={item.heading}
          image={item.image}
          text={item.text}
        />
      </div>
    ))}
  </div>
);

LowerOfferings.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      heading: PropTypes.string,
      text: PropTypes.string
    })
  )
};

export default LowerOfferings;
